import Vue from 'vue';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import Treeselect from '@riophae/vue-treeselect';
import Vuelidate from 'vuelidate';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import accessibilityInit from 'highcharts/modules/accessibility';
import sankeyInit from 'highcharts/modules/sankey';
import highchartsMore from 'highcharts/highcharts-more';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

import _ from 'lodash';

import App from './App.vue';
import router from './router/auto';
import store from './store';
import interceptorsSetup from './providers/services/interceptors';
import Components from './components';
import globalMixin from './providers/helpers/global';
import '@/plugins/log';

import './scss/main.scss';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

accessibilityInit(Highcharts);
sankeyInit(Highcharts);
highchartsMore(Highcharts);
interceptorsSetup();

Vue.use(Vuelidate);
Vue.mixin(globalMixin);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(HighchartsVue);
Vue.use(Components);
Vue.component('VueSlider', VueSlider);
Vue.component('TreeSelect', Treeselect);
Vue.prototype._ = _;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
