<template>
  <div class="card-body">
    <div class="row align-items-center mb-4">
      <div class="col">
        <ScenarioGroupButtons
          :buttons="defaultScenarioButtons"
          :default-value="defaultValue"
          @send-type="getScenarioGraphType"
        />
      </div>
      <div class="col">
        <main-filter-switcher
          :g-filters="gFilters"
          :selected-fn="selectMainFilterItemComprative"
          :multi="true"
          :selection="selection"
          :allowed="allowed"
          :selection-disabled="true"
          :global-option="true"
          :global-selected-disabled="true"
          :object-value="true"
        />
        <ScenariosSelector
          v-if="!loadingGraphType"
          class="mt-2"
          :hidden="scenarioMetadata ? [] : ['target']"
          :displayed-scenarios="['predicted', 'optimal', 'target', 'current']"
          @changed="updateEffectifGlobalSeries"
        />
      </div>
    </div>
    <NoData v-if="!workforceCount" />
    <template v-else>
      <div v-if="loadingGraph" class="d-flex justify-content-center">
        <span>
          <i class="fas fa-spinner-third fa-spin" />
        </span>
      </div>
      <highcharts v-else :options="chartGlobal" />
    </template>
    <div>
      <DataLabelsSwitcher />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { formatCostType } from '@/providers/helpers/functions';
import ScenariosSelector from '@/components/shared/ScenariosSelector.vue';
import ScenarioGroupButtons from '@/components/shared/ScenarioGroupButtons.vue';
import DataLabelsSwitcher from '@/components/shared/DataLabelsSwitcher.vue';
import NoData from '../../../all/NoData.vue';
import MainFilterSwitcher from '@/components/analysis-tabs/effectifs/MainFilterSwitcher.vue';
import MainAnalysisFilterMixin from '@/mixins/MainAnalysisFilterMixin';
import SetChartSelectsMixin from '@/mixins/SetChartSelectsMixin';


const defaultScenarioButtons = [
  {
    name: 'EFF',
    key: 'effective',
    popover: 'Nombre de collaborateurs présents à la date de projection.',
    active: true
  },
  {
    name: 'ETP',
    key: 'fte',
    popover: `Equivalent Temps Plein, Nombre de collaborateurs rapporté
    second au temps de travail effectif à la date de projection.`,
    active: false
  },
  {
    name: 'Coût',
    key: 'budget',
    active: false
  },
]

const chartGlobal = (categories, showDataLabels) => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: null,
  legend: { enabled: false },
  xAxis: {
    categories,
  },
  yAxis: {
    title: null,
  },
  tooltip: {
    pointFormat: '<span>{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: showDataLabels,
      },
    },
  },
  series: [],
});

export default {
  components: {
    NoData,
    ScenariosSelector,
    DataLabelsSwitcher,
    ScenarioGroupButtons,
    MainFilterSwitcher
  },
  mixins: [MainAnalysisFilterMixin, SetChartSelectsMixin],
  props: {
    workforceCount: {
      type: Number,
      required: false,
      default: () => 0,
    },
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {}
    },
    allowed: {
      type: Object,
      required: false,
      default: () => {},
    },
    perimeterFilters: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      currentSelection: [],
      currentLabels: [],
      globalEffectifSeries: [],
      defaultScenarioButtons,
      defaultValue: 'effective',
      loadingGraph: false,
      loadingGraphType: false,
      formatCostType,
    };
  },
  computed: {
    ...mapGetters({
      globalComparativeByScenarioTarget: 'analysis/globalComparativeByScenarioTarget',
      getEffectifsScenarioTarget: 'effectifs/getEffectifsScenarioTarget'
    }),
    chartGlobal() {
      // eslint-disable-next-line
      const chart = chartGlobal(this.labels, this.showDataLabels);
      chart.series = this.globalEffectifSeries;
      if (this.defaultValue === 'effective') {
        chart.tooltip = {
          formatter: function () {
            const value = this.y;
            const formattedValue = formatCostType(value, 'effective');
            return '<b>' + this.x + '<br>' + this.series.name +
              '</b> : <b>' + formattedValue + '</b>';
          }
        };
      } else if (this.defaultValue === 'fte') {
        chart.tooltip = {
          formatter: function () {
            const value = this.y;
            const formattedValue = formatCostType(value, 'fte');
            return '<b>' + this.x + '<br>' + this.series.name +
              '</b> : <b>' + formattedValue + '</b>';
          }
        };
      } else {
        chart.tooltip = {
          formatter: function () {
            const value = this.y;
            const formattedValue = formatCostType(value, 'budget');
            return '<b>' + this.x + '<br>' + this.series.name +
              '</b> : <b>' + formattedValue + '</b>';
          }
        };
      }
      return chart;
    }

  },
  watch: {
    getEffectifsScenarioTarget: {
      handler(val) {
        this.defaultValue = val
      },
      immediate: true,
    },
    globalComparativeByScenarioTarget: {
      handler() {
        this.loadingGraphType = true;
        setTimeout(() => {
          this.loadingGraphType = false;
        }, 200)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      setAllDataComparativeByScenarioTarget: 'analysis/setAllDataComparativeByScenarioTarget',
    }),
    ...mapActions({
      setEffectifsScenarioTarget: 'analysis/effectifs/setEffectifsScenarioTarget',
      getScenarioBasicResults: 'analysis/loadScenarioBasicData',
    }),
    getScenarioGraphType(type) {
      this.defaultValue = type
      this.setEffectifsScenarioTarget(type)
    },
    updateEffectifGlobalSeries(scenarios) {
      const { data } = this.globalComparativeByScenarioTarget;
      this.globalEffectifSeries = [];
      scenarios.forEach((entity) => {
        if (data[entity]) {
          this.globalEffectifSeries.push({
            ...data[entity],
            dataLabels: { color: data[entity].color },
          });
        }
      });
    },
    noDataTab(i) {
      // eslint-disable-next-line
      return !['internalExternal','seniorityGender', 'seniority', 'manager', 'gender', 'contrat', 'age'].includes(
        i.id
      );
    },
    async selectMainFilterItemComprative(item, values) {
      this.loadingGraph = true;
      const val = values[values.length - 1];
      const id = this.$route.params.id
      const id2 = this.$route?.query?.id2
      const [global, ...jobValues]= values
      for(const job of jobValues) {
      // eslint-disable-next-line
        const dataTypeAll = await this.getScenarioBasicResults({ id, id2, filters: this.perimeterFilters, type: 'all' })
        await this.setAllDataComparativeByScenarioTarget({
          [job._id]: dataTypeAll
        })
      }
      if (!values || !values.length) {
        return;
      }
      const data = Array.isArray(values) ? values : [values];
      if (typeof data[0] === 'object') {
        this.buildSelect(item, data, true);
      } else {
        this.buildSelect(item, data, false);
      }
      this.loadingGraph = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs-quantitative {
  ::v-deep .card-header {
    padding: 0 !important;
  }
  ::v-deep .nav-link{
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
